export const GlobalStateController = () => {
	return null;
}

GlobalStateController.getServerProps = () => {
	return new Promise((resolve) => {
		Promise.all([
		]).then(([locations]) => {
			resolve({
				locations,
			})
		}).catch(e => {
			console.error('Initial props error: ', e);
			resolve({
			})
		})
	})
}