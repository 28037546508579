import loadable from '@loadable/component'

export default {
	home: {
		path: '/',
		exact: true,
		layout: 'base',
		layoutClass: 'home',
		head: {
			title: 'A fresh perspective in digital transformation'
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/home')),
	},
	notFound: {
		path: '*',
		layout: 'base',
		status: 404,
		head: {
			title: 'Sayfa Bulunamadı',
		},
		component: loadable(() => import('/views/pages/not-found')),
	},
	error: {
		path: '*',
		layout: 'base',
		status: 500,
		head: {
			title: 'Bir Hata İle Karşılaşıldı',
		},
		component: loadable(() => import('/views/pages/error')),
	}
}